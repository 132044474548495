import React, { ReactElement } from 'react'
import { Colors,Styles } from '../../constants'
import {asRgbaString} from '../../utils'

interface IProps {
  children: string | ReactElement | ReactElement[]
}

export default (props: IProps) => (
  <div className='Titlebar'>
    <style jsx>{`
      .Titlebar {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 100%;
        background: ${Colors.accent.primary.base};
        color: ${Colors.background.base};
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 2px;
        border-radius: 0 0 ${Styles.bar.borderRadiusH} ${Styles.bar.borderRadiusH} / 0 0 ${Styles.bar.borderRadiusV} ${Styles.bar.borderRadiusV};
        box-shadow: 0px 0px 10px ${asRgbaString(Colors.accent.primary.base, 0.5)};
      }
    `}</style>
    {props.children}
  </div>
)
