import React from "react"
import { Switch, Route, useLocation, useHistory } from "react-router-dom"

import { Colors, Styles } from "./constants"
import Navbar from "./components/navbar/navbar"
import NavbarItem from "./components/navbar/navbar-item"
import { ReactComponent as SearchIcon } from "./icons/outline/search-outline.svg"
import { ReactComponent as BookIcon } from "./icons/outline/book-open-outline.svg"
import { ReactComponent as PersonIcon } from "./icons/outline/person-outline.svg"
import { ReactComponent as ClockIcon } from "./icons/outline/clock-outline.svg"
import { ReactComponent as MessageIcon } from "./icons/outline/message-square-outline.svg"
import Page from "./components/page/page"
import { motion } from "framer-motion"

const navItems = [
  { path: "/feed", icon: <ClockIcon /> },
  { path: "/browse", icon: <SearchIcon /> },
  { path: "/", icon: <BookIcon /> },
  { path: "/message", icon: <MessageIcon /> },
  { path: "/profile", icon: <PersonIcon /> },
]

export default () => {
  const location = useLocation()
  const history = useHistory()

  return (
    <div className="root">
      <style jsx>{`
        .root {
          background: ${Colors.background.base};
          font-family: "Comfortaa", cursive;
          color: ${Colors.text.base};
          box-sizing: border-box;
          height: 100%;
          user-select: none;
        }

        .content {
          height: calc(100% - ${Styles.bar.height});
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .navbar {
          height: ${Styles.bar.height};
        }
      `}</style>

      <Switch>
        <div className="content">
          <Route exact path="/">
            <Page title="Library"></Page>
          </Route>
          <Route path="/feed">
            <Page title="Feed"></Page>
          </Route>
          <Route path="/browse">
            <Page title="Browse"></Page>
          </Route>
          <Route path="/message">
            <Page title="Direct Messages"></Page>
          </Route>
          <Route path="/profile">
            <Page title="Profile"></Page>
          </Route>
        </div>
      </Switch>
      <div className="navbar">
        <Navbar>
          {navItems.map((val, idx) => (
            <div
              key={idx}
              onClick={() => {
                history.push(val.path)
              }}
            >
              <NavbarItem active={location.pathname === val.path}>
                {val.icon}
              </NavbarItem>
            </div>
          ))}
        </Navbar>
      </div>
    </div>
  )
}
