export const asRgbString = (hex: string) => {
    const c = parseInt(hex, 16)
    const r = (c >> 16) & 255
    const g = (c >> 8) & 255
    const b = c & 255

    return `rgb(${r}, ${g}, ${b})`
}
export const asRgbaString = (hex: string, opactity) => {
    hex = hex.replace('#', '')
    const c = parseInt(hex, 16)
    const r = (c >> 16) & 255
    const g = (c >> 8) & 255
    const b = c & 255
    return `rgb(${r}, ${g}, ${b}, ${opactity})`
}
