import React, { ReactNode } from "react"
import { motion } from "framer-motion"

import { Colors, Transitions } from "../../constants"
import { asRgbaString } from "../../utils"

interface IProps {
  children: ReactNode | ReactNode[]
  active?: boolean
}

const size = "25px"

export default ({ children, active = false }: IProps) => {
  const color = active ? Colors.accent.primary.base : Colors.text.light
  const opacity = active ? 0.8 : 0.5

  return (
    <motion.div
      animate={{ scale: active ? 1.2 : 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="root">
        <style jsx>{`
          .root {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .icon::selection {
            background: #ffb7b7; /* WebKit/Blink Browsers */
          }

          .icon {
            filter: drop-shadow(0 0 5px ${asRgbaString(color, opacity)});
            height: ${size};
            width: ${size};
            padding: 5px;
            backface-visibility: hidden;
          }
        `}</style>
        <motion.div
          initial={{ fill: Colors.text.light }}
          animate={{ fill: color }}
          transition={{ duration: Transitions.duration.base }}
        >
          <div className="icon">{children}</div>
        </motion.div>
      </div>
    </motion.div>
  )
}
