import React from "react"
import Titlebar from "../titlebar/titlebar"
import { Styles, Transitions } from "../../constants"
import { motion } from "framer-motion"

interface IProps {
  children?: any
  title: string
}

export default (props: IProps) => (
  <div className="root">
    <style jsx>{`
      .root {
        height: 100%;
        width: 100%;
      }

      .titlebar {
        width: 100%;
        height: ${Styles.bar.height};
      }
    `}</style>
    <div className="titlebar">
      <Titlebar>
        <motion.div
          initial={{ opacity: 0.5, y: 7 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: Transitions.duration.base }}
        >
          {props.title}
        </motion.div>
      </Titlebar>
    </div>
    {props.children}
  </div>
)
