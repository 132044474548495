import React, { ReactElement } from "react"
import { Colors, Styles } from "../../constants"
import { asRgbaString } from "../../utils"

interface IProps {
  children: ReactElement | ReactElement[]
}

export default (props: IProps) => (
  <div className="Navbar">
    <style jsx>{`
      .Navbar {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 100%;
        background: ${Colors.background.darker};
        border-radius: ${Styles.bar.borderRadiusH} ${Styles.bar.borderRadiusH} 0
          0 / ${Styles.bar.borderRadiusV} ${Styles.bar.borderRadiusV} 0 0;
        box-shadow: 0px 0px 10px ${asRgbaString(Colors.text.base, 0.1)};
      }
    `}</style>
    {props.children}
  </div>
)
