const colors = {
    "Red": "#ee5d5f",
    "Pink": "#e91e63",
    "Amber": "#ffc107",
    "Orange": "#ff9800",
    "Deep Orange": "#ff5722",
    "Light Green": "#8ebf87",
    "Muted Teal": "#4fb882",
    "Teal": "#30ba8f",
    "Downy": "#61d5be",
    "Cyan": "#00bcd4",
    "Light Blue": "#03a9f4",
    "Blue": "#2196f3",
    "Portage": "#8b87f6",
    "Deep Purple": "#774eb6",
    "Purple": "#9c27b0",
    "Indigo": "#5377c1",
}

const Colors = {
    background: {
        base: '#ffffff',
        darker: '#faf9f5'
    },
    text: {
        light: '#666666',
        lighter: '#282828',
        base: '#212121',
        darker: '#111111'
    },
    accent: {
        primary: {
            base: colors.Portage,
        }
    }
}

const Transitions = {
    duration: {
        base: 0.5
    }
}

const Styles = {
    bar: {
        borderRadiusH: '5%',
        borderRadiusV: "30%",
        height: '50px'
    }
}


export { Colors, Transitions, Styles }